import React from 'react'
import InviteMain from '../Components/InviteMain'
import BottomNavigationArea from '../Components/BottomNavigation'

const Invite = () => {
  return (
    <div>
<InviteMain>
        
        <BottomNavigationArea />
</InviteMain>
    </div>
  )
}

export default Invite