import React from 'react'
import Coupenmainuser from '../Components/Coupenmainuser'
import BottomNavigationArea from '../Components/BottomNavigation'

const CoupenUser = () => {
  return (
    <div>
<Coupenmainuser>
        
        <BottomNavigationArea />

</Coupenmainuser>
    </div>
  )
}

export default CoupenUser;