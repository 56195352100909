import AdminPanel from './Admin';
import LevelContent from './LevelContent';


const Level = ({}) => {
  return (
    <AdminPanel>
      <LevelContent />
    </AdminPanel>
  );
};

export default Level;