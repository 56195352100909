import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, List, ListItem, Select, MenuItem } from '@mui/material';
import axios from 'axios';
const GamesContent = () => {
  const [data, setData] = useState({
    "1min": { sums: {}, periodId: "" },
    "3min": { sums: {}, periodId: "" },
    "5min": { sums: {}, periodId: "" },
    "10min": { sums: {}, periodId: "" }
  });
  
  // State for storing the selected timer
  const [selectedTimer, setSelectedTimer] = useState("1min");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://111club.online/bets/');
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 1000); // Update data every second

    return () => clearInterval(interval); // Cleanup
  }, []);


  const [periodId, setPeriodId] = useState('');
    const [timer, setTimer] = useState('');
    const [colorOutcome, setColorOutcome] = useState('');
    const [numberOutcome, setNumberOutcome] = useState('');
    const [sizeOutcome, setSizeOutcome] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
          await axios.put(`https://111club.online/api/results/${periodId}`, { timer, colorOutcome, numberOutcome, sizeOutcome });
          alert('Result updated successfully');
      } catch (err) {
          console.error('Error updating result:', err);
      }
  };
  return (
    <div>
    <>
      <Box display="flex" justifyContent="center" mt={2}>
        <Select
          value={selectedTimer}
          onChange={(e) => setSelectedTimer(e.target.value)}
        >
          <MenuItem value="1min">1min</MenuItem>
          <MenuItem value="3min">3min</MenuItem>
          <MenuItem value="5min">5min</MenuItem>
          <MenuItem value="10min">10min</MenuItem>
        </Select>
      </Box>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12}>
          <Box p={2}  display="flex" flexDirection="column" alignItems="center" justifyContent="center" mx={2}>
            <Typography variant="h6">{selectedTimer}</Typography>
            <Typography variant="body1">Period ID: {data[selectedTimer].periodId}</Typography>
            <Typography variant="body1">Total Bet Amount</Typography>
            <Box display="flex" flexDirection='row' flexWrap='wrap'>
              {Object.entries(data[selectedTimer].sums).map(([sumKey, sumValue]) => (
                <Box key={sumKey} m={1} p={2} bgcolor="#f0f0f0">
                  <Typography>{sumKey}: {sumValue}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
    <form onSubmit={handleSubmit}>
            <input type="text" value={periodId} onChange={e => setPeriodId(e.target.value)} placeholder="Period ID" required />
            <input type="text" value={timer} onChange={e => setTimer(e.target.value)} placeholder="Timer" required />
            <input type="text" value={colorOutcome} onChange={e => setColorOutcome(e.target.value)} placeholder="Color Outcome" required />
            <input type="text" value={numberOutcome} onChange={e => setNumberOutcome(e.target.value)} placeholder="Number Outcome" required />
            <input type="text" value={sizeOutcome} onChange={e => setSizeOutcome(e.target.value)} placeholder="Size Outcome" required />
            <button type="submit">Update Result</button>
        </form>
    </div>
  );
};

export default GamesContent;
