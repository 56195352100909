import React from 'react'
import HomeMain from '../Components/HomeMain'
import BottomNavigationArea from '../Components/BottomNavigation'

const Home = () => {
  return (
    <div>
<HomeMain>
        
        <BottomNavigationArea />
</HomeMain>
    </div>
  )
}

export default Home;