import React from 'react'
import TransactionHistoryMain from '../Components/TransactionHistoryMain'
import BottomNavigationArea from '../Components/BottomNavigation'


const Transaction = () => {
  return (
    <div>
<TransactionHistoryMain>
        
        <BottomNavigationArea />
</TransactionHistoryMain>
    </div>
  )
}

export default Transaction;









