import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './Pages/Register';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Activity from './Pages/Activity';
import Promotion from './Pages/Promotion';
import Account from './Pages/Account';
import Wallet from './Pages/Wallet';
import Head from './Game/Head'
import LotteryApp from './Game/5d';
import LotteryAppk from './Game/K3';
import LotteryAppt from './Game/Trx';
import { useAuth } from './contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import Timer from './Components/Timer';
import WinGo from './Admin/Components/WinGo';
import Members from './Admin/Components/Member';
import PasswordProtectedRoute from './Components/Hoc';
import Level from './Admin/Components/Level';
import Salary from './Admin/Components/Salary';
import RechargeMain from './Components/RechargeMain';
import WithdrawMain from './Components/WithDrawMain';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import Withdraw from './Admin/Components/Withdraw';
import DepositRequest from './Admin/Components/DepositRequest';
import DepositHistory from './Pages/DepositHistory';
import BetHistory from './Pages/BetHistory';
import WithdrawHistory from './Pages/WithdrawHistory';
import Transaction from './Pages/Transaction';
import CommisionDetailsMain from './Components/CommisionDetailsMain';
import SubordinateDataMain from './Components/SubordinateDataMain';
import Coupen from './Admin/Components/coupen';
import CoupenUser from './Pages/CoupenUser';
import Search from './Admin/Components/Search';
import Invite from './Pages/Invite';
import PaymentComponent from './Components/WowPayment';
import InvitiationRules from './Pages/InvitiationRules';
import Messages from './Pages/Messages';
import Settings from './Pages/Settings'
import Language from './Pages/Language'
import Newsubordinate from './Pages/Newsubordinate';
import Attendance from './Pages/Attendance';
import { AuthProvider } from "./contexts/AuthContext"
import ProtectedRoute from "./Components/ProtectedRoute";

const App = () => {
  const isAuthenticated = useAuth();

  if (isAuthenticated === null) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/members" element={
          <PasswordProtectedRoute>
            <Members />
          </PasswordProtectedRoute>
        } />
        <Route path="/wingo" element={
          <PasswordProtectedRoute>
            <WinGo />
          </PasswordProtectedRoute>
        } />
        <Route path="/deposit-request" element={
          <PasswordProtectedRoute>
            <DepositRequest />
          </PasswordProtectedRoute>
        } />
        <Route path="/coupen" element={
          <PasswordProtectedRoute>
            <Coupen />
          </PasswordProtectedRoute>
        } />
        <Route path="/level" element={
          <PasswordProtectedRoute>
            <Level />
          </PasswordProtectedRoute>
        } />
        <Route path="/withdraw-request" element={
          <PasswordProtectedRoute>
            <Withdraw />
          </PasswordProtectedRoute>
        } />
        <Route path="/salary" element={
          <PasswordProtectedRoute>
            <Salary />
          </PasswordProtectedRoute>
        } />

        <Route path="/search" element={
          <PasswordProtectedRoute>
            <Search />
          </PasswordProtectedRoute>
        } />

        {/* <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/timer" element={<Timer />} />
        <Route path="/login" element={<Login />} />
        <Route path="/activity" element={<ProtectedRoute><Activity /></ProtectedRoute>} />
<Route path="/coupen-user" element={<ProtectedRoute><CoupenUser /></ProtectedRoute>} />
<Route path="/transaction" element={<ProtectedRoute><Transaction /></ProtectedRoute>} />
<Route path="/5d" element={<ProtectedRoute><LotteryApp /></ProtectedRoute>} />
<Route path="/k3" element={<ProtectedRoute><LotteryAppk /></ProtectedRoute>} />
<Route path="/trx" element={<ProtectedRoute><LotteryAppt /></ProtectedRoute>} />
<Route path="/subordinate-data" element={<ProtectedRoute><SubordinateDataMain /></ProtectedRoute>} />
<Route path="/commision-details" element={<ProtectedRoute><CommisionDetailsMain /></ProtectedRoute>} />
<Route path="/bet-history" element={<ProtectedRoute><BetHistory /></ProtectedRoute>} />
<Route path="/deposit-history" element={<ProtectedRoute><DepositHistory /></ProtectedRoute>} />
<Route path="/withdraw-history" element={<ProtectedRoute><WithdrawHistory /></ProtectedRoute>} />
<Route path="/withdraw" element={<ProtectedRoute><WithdrawMain /></ProtectedRoute>} />
<Route path="/recharge" element={<ProtectedRoute><RechargeMain /></ProtectedRoute>} />
<Route path="/promotion" element={<ProtectedRoute><Promotion /></ProtectedRoute>} />
<Route path="/account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
<Route path="/wallet" element={<ProtectedRoute><Wallet /></ProtectedRoute>} />
<Route path="/Head" element={<ProtectedRoute><Head /></ProtectedRoute>} />
<Route path="/invite" element={<ProtectedRoute><Invite /></ProtectedRoute>} />
<Route path="/payment" element={<ProtectedRoute><PaymentComponent /></ProtectedRoute>} />
<Route path="/invitation-rules" element={<ProtectedRoute><InvitiationRules /></ProtectedRoute>} />
<Route path="/messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />
<Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
<Route path="/language" element={<ProtectedRoute><Language /></ProtectedRoute>} />
<Route path="/newsubordinate" element={<ProtectedRoute><Newsubordinate /></ProtectedRoute>} />
<Route path="/attendance" element={<ProtectedRoute><Attendance /></ProtectedRoute>} />
<Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      </Routes>
    </Router>
    </AuthProvider>
  );
}
export default App;