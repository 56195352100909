import React from 'react'
import  LanguageMain  from '../Components/LanguageMain'
import BottomNavigationArea from '../Components/BottomNavigation'


const   Language = () => {
  return (
    <div>
<LanguageMain>
        
        <BottomNavigationArea />
</LanguageMain>
    </div>
  )
}

export default Language