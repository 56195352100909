import React from 'react'
import NewsubordinateMain from '../Components/NewsubordinateMain'
import BottomNavigationArea from '../Components/BottomNavigation'

const Newsubordinate = () => {
  return (
    <div>
<NewsubordinateMain>
        
        <BottomNavigationArea />
</NewsubordinateMain>
    </div>
  )
}

export default Newsubordinate