import AdminPanel from './Admin';
import SalaryContent from './SalaryContent';


const Salary = ({}) => {
  return (
    <AdminPanel>
      <SalaryContent />
    </AdminPanel>
  );
};

export default Salary;