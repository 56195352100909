import AdminPanel from './Admin';
import WithdrawContent from './WithdrawContent'; 


const WinGo = ({}) => {
  return (
    <AdminPanel>
      <WithdrawContent />
    </AdminPanel>
  );
};

export default WinGo;