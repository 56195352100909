import React, { useEffect,useState} from 'react'
import Mobile from '../Components/Mobile';
import IconButton from '@mui/material/IconButton';

import SmsIcon from '@mui/icons-material/Sms';
import DownloadIcon from '@mui/icons-material/Download';
import { Typography,  Card, CardMedia, CardContent,Grid , Box} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';



const MessagesMain= ({ children }) => {

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setVh);
    setVh();

    return () => window.removeEventListener('resize', setVh);
  }, []);
  const navigate = useNavigate();
  const navigateToPage2 = () => {
    navigate('/coupen-user'); // Replace '/path-to-page' with the actual path
  };



    const [user, setUser] = useState(null);
    console.log(user);
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const response = await axios.get('http://localhost:3000/notifications', { withCredentials: true });
          setUser(response.data);
        } catch (err) {
          console.error(err);
        }
      };
  
      fetchUserData();
    }, []);

    
 
  return (
    <div>
      <Mobile>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
        >
          <Box flexGrow={1}>


            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
                backgroundColor: 'RGB(54,142,255)',
                padding: '8px 16px',
                color: 'white'
                
              }}
            >
              <Grid item xs={6} textAlign="left">
                <span style={{ fontWeight: "bold" }}>Notification</span>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <IconButton color="inherit">
                  <SmsIcon />
                </IconButton>
                <IconButton color="inherit">
                  <DownloadIcon />
                </IconButton>
              </Grid>
            </Grid>

            {/* //content */}
            {user && user.notifications.map((notification, index) => (
  <Box key={index} bgcolor="white" boxShadow={3} mt={3} borderRadius={5} px={2}>
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" component="div">
            {notification.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="text.secondary">
            {new Date(notification.date).toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="text.secondary">
          {notification.message}
        </Typography>
      </Grid>
    </Grid>
  </Box>
))}
            {/* content end */}
          </Box>


          
{children}

        </Box>
      </Mobile>
    </div>
  )
}

export default MessagesMain;