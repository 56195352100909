import React from 'react'
import './CoinBox.css'
import coinimg from '../../assets/coin.png'

function CoinBox(props) {
  return (
    <div className='coinbox-container'>
        <div className='coinbox-amount' style={{color:"white"}}>{props.coinboxAmount}</div>
        <div className="coinbox-image">
            <img src={coinimg} alt="" />
        </div>
        <div className="coinbox-day" style={{color:"white"}}>{props.coinboxDay}</div>
    </div>
  )
}

export default CoinBox