import React from 'react'
import PromotionMain from '../Components/PromotionMain'
import BottomNavigationArea from '../Components/BottomNavigation'

const Promotion = () => {
  return (
    <div>
<PromotionMain>
        
        <BottomNavigationArea />
</PromotionMain>
    </div>
  )
}

export default Promotion;