import AdminPanel from './Admin';
import SearchMain from './SearchMain';


const Salary = ({}) => {
  return (
    <AdminPanel>
      <SearchMain />
    </AdminPanel>
  );
};

export default Salary;