import React from 'react'
import MessagesMain from '../Components/MessagesMain'
import BottomNavigationArea from '../Components/BottomNavigation'

const Messages = () => {
  return (
    <div>
<MessagesMain>
        
        <BottomNavigationArea />
</MessagesMain>
    </div>
  )
}

export default Messages