import React from 'react'
import InvitiationRulesMain from '../Components/InvitiationRulesMain'
import BottomNavigationArea from '../Components/BottomNavigation'

const InvitationRules = () => {
  return (
    <div>
<InvitiationRulesMain>
        
        <BottomNavigationArea />
</InvitiationRulesMain>
    </div>
  )
}

export default InvitationRules